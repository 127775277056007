<template>
  <div class="login-wrapper">
    <center>
    <div class="login-img">
      <img src="@/assets/img/logo.png" width="70%" style="height:100px" alt="">
    </div>
    </center>
      <van-form class="form">
        <van-cell-group>
          <van-field
            class="ipt"
            name="手机号"
            label="手机号"
            v-model="mobile"
            left-icon="mobile"
            placeholder="请输入手机号"
            clearable
            :error-message="mobileCheckMsg"
          />
          <van-field
            :class="$style.field"
            class="ipt"
            center
            name="验证码"
            label="验证码"
            left-icon="mima54"
            v-model="sms"
            placeholder="请输入验证码"
            v-tap @click-icon="sms = ''"
            clearable
            :error-message="smsCheckMsg"
          >
            <van-button tag="span" color="#00A39B" slot="button" plain size="small" type="primary" class="sms-btn" v-tap @tap.native="sendCheckCode" :disabled="!canGetSms">{{ !!timer ? `重新获取(${countDown} s)` : '获取验证码' }}</van-button>
          </van-field>
        </van-cell-group>
      </van-form>
      <div class="btn" style="margin: 16px;">
        <van-button tag="div" :block="true" slot="button" type="primary" size="normal" class="submit-btn" v-tap @tap.native="handleSubmit" :disabled="!canSubmit">绑 定</van-button>
      </div>
      <div class="footer">
        <span>© 2022 福州得道助力信息科技有限公司</span>
      </div>
  </div>
</template>
<script>
import { userDB } from '@/db'
import { Toast } from 'vant'

export default {
  data() {
    return {
      sms: '',
      mobile: '',
      smsCheckMsg: '',
      timer: null,
      countDown: 60,
      parent:null
    }
  },
  computed: {
    mobileCheckMsg() {
      if (!this.mobile) return '请输入手机号'
      const reg = /^1[3456789]\d{9}$/
      if (!reg.test(this.mobile)) {
        return '请输入11位正确的手机号'
      }
      return ''
    },
    canGetSms() {
      return !this.mobileCheckMsg && !this.timer
    },
    canSubmit() {
      return !this.smsCheckMsg && !this.mobileCheckMsg && this.mobile && this.sms
    },
    font() {
      return process.env.VUE_APP_TITLE_FONT
    },
    logo() {
      // const logoPath = processConfig.VUE_APP_LOGO
      return require(`@/assets/img/login-logo.png`)
    }
  },
  watch: {
    sms(val, before) {
      if (this.mobile && this.mobile !== '' && val.length === 4) {
        // this.handleSubmit()
      }
    }
  },
  methods: {
    async handleSubmit () {
      if (this.mobileCheckMsg) {
        Toast.fail(this.mobileCheckMsg);
        return
      }
      if (!this.sms) {
        Toast.fail("验证码不为空！");
        return
      }
      const appId = await userDB.get('appId')
      this.$store.dispatch('userLogin', {
        phone: this.mobile,
        validCode: this.sms,
        appId: appId
      }).then(userInfo => {
        this.$router.push({
          name: 'home'
        })
      }).catch(error => {
        Toast.fail(error.message)
      })
    },
    sendCheckCode() {
      if (this.timer || this.mobileCheckMsg) {
        Toast.fail(this.mobileCheckMsg);
        return
      }

      this.$store.dispatch('sendSms', this.mobile)
      this.timer = setInterval(() => {
        --this.countDown
        if (this.countDown === 0) {
          clearInterval(this.timer)
          this.countDown = this.$options.data()['countDown']
          this.timer = null
        }
      }, 1000)
    }
  }
}
</script>

<style lang="scss" module>
// $main-color: #FFBE3E;
// .field {
//   :global {
//     i.van-cell__left-icon {
//       color: $main-color;
//       margin-right: 10px;
//       font-size: 46px;
//     }
//     .van-field__icon {
//       i.van-icon {
//         font-size: 36px;
//       }
//     }
//   }
// }
</style>

<style lang="scss" scoped>
// $main-color: #FFBE3E;
  .login-wrapper {
  //   padding: 35px 35px;
  //   font-size: 30px;
  //   color: #1E1E1E;
  //   font-weight: 600;
  //   ::-webkit-input-placeholder { /* WebKit browsers */
  //     color: #999;
  //   }
    .login-img{
      margin: 50px 0 20px 0;
    }
    .submit-btn{
      background-color: #00A39B;
    }

    .sms-btn{

    }
  //   ::-moz-placeholder { /* Mozilla Firefox 19+ */
  //     color: #999;
  //   }

  //   :-ms-input-placeholder { /* Internet Explorer 10+ */
  //     color: #999;
  //   }
  //   .title {
  //     font-size: 24px;
  //     color: #1E1E1E;
  //     font-weight: 400;
  //     margin-top: 20px;
  //   }
  //   .form {
  //     // margin-top: 20px;
  //     .ipt {
  //       font-size: 20px;
  //       color: #333;
  //       padding: 15px 0;
  //       font-weight: 400;
  //       opacity: 1;
  //       border: none;
  //       border-bottom:  1px solid #dadada;
  //     }
  //   }
  //   .btn {
  //     width: 90%;
  //     margin: 0 auto;
  //     margin-top: 15px;
  //     .submit-btn {
  //       width:100%;
  //       height:40px;
  //       border-radius:44px;
  //       border: none;
  //       background: #FFBE3E;
  //       // box-shadow:20px 0px 20px rgba(242,72,82,0.2);
  //       opacity: 1;
  //       color: #fff;
  //       font-size: 16px;
  //       margin-top: 120px;
  //     }
  //   }
    .footer {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      width: 100%;
      text-align: center;
      font-size: 15px;
      color: #AFAFAF;
      opacity: .49;
    }
  //   :global {
  //     .sms-btn {
  //       // font-size: 30px;
  //       // color: #66A6FF;
  //       // background: none;
  //       // border: none;
  //       // border-radius: 0;
  //       // font-weight: 300;
  //       // opacity: 1;
  //       // color: $main-color;
  //       // background: none;
  //       // border: none;
  //     }
  //   }
  }
</style>
